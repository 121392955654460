.container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 1200px;

    >* {
        z-index: 2;
    }

    margin-top: 22px;

    .left {
        margin-left: -25px;
    }

    .right {
        display: flex;
        margin-bottom: 10px;

        button {
            height: 42px;
            padding: 0 20px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            cursor: pointer;
            outline: none;
            border: 1px solid rgb(221, 46, 46);

            font-size: 18px;
            text-transform: uppercase;

            background-color: rgb(221, 46, 46);
            color: white;

            &:first-of-type {
                color: rgb(221, 46, 46);
                background: transparent;
            }
        }

        a.where_buy {
            text-decoration: none;
            line-height: 40px;
            outline: none;
            padding: 0 20px;
            cursor: pointer;
            border: 1px solid rgb(221, 46, 46);
            text-transform: uppercase;
            margin-right: 5px;
            font-size: 18px;
            color: rgb(221, 46, 46);
        }
    }

    .background {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;

        img {
            width: 100%;
            opacity: 0.7;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            opacity: 0.5;
            width: 100%;
            height: 70%;
        }
    }

    @media(min-width: 3000px) {
        .background {
            left: calc(50% - 1500px);
        }
    }

    @media(max-width: 1900px) {
        .left {
            .logo {
                height: 88px;
            }
        }

        .right {
            margin-bottom: 5px;
            button {
                height: 33px;
                font-size: 16px;
            }
            a.where_buy {
                line-height: 31px;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 1440px) {
        width: unset;
        align-self: stretch;
        margin: 0 120px;
    }

    @media (max-width: 1150px) {
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 360px;
        align-self: center;

        .left {
            margin-left: 0;
        }

        .right {
            button, a.where_buy {
                font-size: 11px;
            }
        }

        .background {
            top: 110px;
            left: unset;

            transform: scale(2);

            display: flex;
            justify-content: center;

            img {
                height: 170px;
                width: auto;
            }

            &:after {
                background: linear-gradient(0deg, rgba(247, 246, 246, 0) 0%, #f7f6f6 100%);
                left: 0;
                opacity: 1;
            }

            .leftGradient {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgb(247, 246, 246) 0%, rgba(247, 246, 246,0) 100%);
                width: 25%;
                height: 100%;
            }

            .rightGradient {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(247, 246, 246,0) 0%, rgb(247, 246, 246) 100%);
                width: 10%;
                height: 100%;
            }
        }
    }
}