.container {
  position: relative;

  background-color: rgb(233, 236, 239);
  width: 306px;
  height: 403px;

  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    margin-top: 70px;
  }

  .content {
    position: absolute;

    top: 180px;
    left: 30px;

    width: calc(100% - 60px);
    text-align: center;

    font-size: 30px;
    color: rgb(50, 56, 56);
    line-height: 1.467;
  }
  
  @media (max-width: 1900px) {
    width: 266px;
    height: 354px;

    img {
      margin-top: 40px;
    }

    .content {
      top: 156px;

      font-size: 24px;
      font-weight: 300;
      line-height: 1.42;
    }
  }

  @media (max-width: 1150px) {
    width: 350px;
    height: auto;
    padding-bottom: 20px;

    margin-bottom: 2px;

    img {
      margin-top: 0;

      transform: scale(0.55);
    }

    .content {
      position: unset;
      width: 295px;

      font-size: 16px;
      font-weight: 300;
      line-height: 1.25;
    }
  }
}