.container {
  margin-top: 160px;
  background: url("./background.png") center no-repeat;
  background-size: auto 100%;
  background-color: #DD2E2E;
  width: 100vw;
  height: 449px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Comfortaa', cursive;
  font-size: 40px;
  color: rgb(251, 236, 236);
  font-weight: bold;
  line-height: 1.2;

  button {
    outline: none;
    cursor: pointer;

    border: 2px solid rgb(251, 236, 236);
    background: transparent;
    width: 536px;
    height: 96px;

    font-size: 36px;
    font-family: 'Comfortaa', cursive;
    color: rgb(251, 236, 236);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;

    margin: 0 40px;
  }

  @media (max-width: 1900px) {
    margin-top: 98px;

    height: 281px;

    font-size: 30px;;
    line-height: normal;

    button {
      width: 411px;
      height: 81px;

      font-size: 30px;
      line-height: normal;
    }
  }

  @media (max-width: 1150px) {
    margin-top: 50px;

    height: 181px;

    flex-direction: column;

    font-size: 24px;
    font-weight: bold;

    button {
      width: 350px;
      height: 51px;

      margin: 10px 0;

      font-size: 24px;
      font-weight: bold;
    }
  }
}