.container {
  .items {
    display: flex;
    justify-content: space-between;

    width: 1290px;
  }

  ul {
    margin: 80px 0 0;
    padding: 0 0 0 104px;
    background: url("./list.png") no-repeat left 15px;
    width: 1186px;
    list-style: none;

    li {
      font-size: 30px;
      color: rgb(50, 56, 56);
      line-height: 1.467;
      margin-bottom: 44px;
    }
  }

  @media (max-width: 1900px) {
    .items {
      width: 1070px;
    }

    ul {
      width: 964px;
      padding-top: 5px;

      background: url("./list_1300.png") no-repeat left 15px;

      li {
        font-size: 24px;
        font-weight: 300;
        line-height: 1.42;

        margin-bottom: 34px;
      }
    }
  }

  @media (max-width: 1150px){
    .items {
      flex-direction: column;
      width: 350px;
    }

    ul {
      margin-top: 20px;
      background: url("./list_360.png") no-repeat left 15px;
      width: 290px;
      padding-left: 58px;
      padding-top: 12px;

      li {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.13;

        margin-bottom: 23px;
      }
    }
  }
}