.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  position: relative;
  overflow-x: hidden;

  background-color: #F7F6F6;
}