.container {
  padding-left: 60px;

  width: 900px;

  font-size: 24px;
  color: rgb(50, 55, 56);
  line-height: 1.417;

  margin-bottom: 30px;

  background: url("../../../Section4/list_item.png") no-repeat;
  background-position: 0 9px;

  small {
    display: block;
  }

  opacity: 0;
  transition: 0.3s 0s;
  &.visible {
    opacity: 1;
    transition-delay: 0.3s;
  }
}