.item {
  display: flex;
  align-items: center;
  position: relative;

  >* {
    flex-shrink: 0;
  }

  .content {
    margin: 0 120px;
    width: 925px;

    font-size: 30px;
    color: rgb(50, 55, 56);
    line-height: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .description {
      margin-top: 10px;

      font-size: 24px;
      line-height: 0.75;
    }

    position: relative;
    height: 202px;

    .point {
      position: absolute;
      left: 0;

      &:first-of-type {
        top: 33px;
      }

      &:last-of-type {
        top: 135px;
      }
    }

    &:nth-child(odd) {
      text-align: right;

      .point {
        left: unset;
        right: 0;
      }
    }
  }

  &.three {
    .content {
      height: 245px;
      .point {
        &:nth-child(1) {
          top: -10px;
        }

        &:nth-child(2) {
          top: 103px;
        }

        &:nth-child(3) {
          top: 220px;
        }
      }
    }
  }

  padding-bottom: 140px;
  
  @media (max-width: 1900px) {
    .content {
      height: 153px;
      margin: 0 85px;
      width: 800px;

      font-size: 24px;
      font-weight: 300;
      line-height: 1.25;

      .description {
        margin-top: 0;

        font-size: 20px;
        line-height: 0.9;
      }

      .point {
        &:first-of-type {
          top: 22px;
        }

        &:last-of-type {
          top: 98px;
        }
      }
    }

    &.three {
      .content {
        height: 180px;
        .point {
          &:nth-child(1) {
            top: -12px;
          }

          &:nth-child(2) {
            top: 72px;
          }

          &:nth-child(3) {
            top: 156px;
          }
        }
      }
    }

    padding-bottom: 40px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 1150px) {
  .container {
    width: 360px;

    .item {
      padding-bottom: 0;
      .content {
        margin: 0;
        width: 100%;
        height: unset;

        .point {
          position: unset;
          display: flex;
          flex-direction: column;
          top: unset;
          width: 290px;
          padding-left: 60px;

          font-size: 18px;
          line-height: 1;
          font-weight: 300;
          text-align: left;

          margin-bottom: 25px;

          background: url("./list_item.png") no-repeat;
          background-position: 0 2px;

          >div {
            width: 100%;
          }

          .description {
            font-size: 16px;
            line-height: 1.13;
          }
        }
      }

      &:last-of-type {
        .content {
          .point:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    position: relative;
    &:after {
      content: "";

      position: absolute;
      top: 104px;
      left: 5px;

      display: block;
      width: 2px;
      height: 700px;

      background: url("../../../Resources/Images/dash_line.png") repeat-y;
    }
  }
}