.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .slide {
    margin-left: -370px;
    margin-top: -30px;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.6s ease-in;
    z-index: 2;
    opacity: 0;
  }

  .currentSlide {
    position: unset;
    transition-timing-function: ease-out;
    opacity: 1;
    z-index: 1;
  }

  .topText, .bottomText {
    position: absolute;
    z-index: 3;
    width: 340px;

    font-size: 23px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;

    small {
      font-size: 18px;
      line-height: 1.33;
    }

    opacity: 0;
    transition: opacity 0.3s, top 0s 0.3s, font-weight 0s 0.3s, font-size 0s 0.3s, line-height 0s 0.3s;
    transition-timing-function:  ease-in;

    &.currentSlide {
      transition-timing-function: ease-out;
      transition-delay: 0.3s;
      opacity: 1;
    }
  }

  .topText {
    top: -5px;
  }

  .bottomText {
    top: 255px;
  }

  &.slide2 {
    .topText {
      font-size: 24px;
      line-height: 1.25;
    }

    .bottomText {
      top: 275px;
    }
  }
}