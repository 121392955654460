.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .topText {
    position: absolute;
    z-index: 3;
    width: 1200px;

    font-size: 30px;
    color: rgb(50, 55, 56);
    line-height: 1.333;
  }

  .slide {
    position: absolute;
    top: -40px;
    left: 0;
    transition: opacity 1s ease-in;
    z-index: 2;
    opacity: 0;
  }

  .currentSlide {
    margin-top: -40px;
    position: unset;
    transition-timing-function: ease-out;
    opacity: 1;
    z-index: 1;
  }

  ul {
    margin-top: -50px;
    width: 1000px;
    list-style: none;
    padding-left: 10px;

    position: relative;
    &:after {
      content: "";

      position: absolute;
      top: -330px;
      left: 10px;

      display: block;
      width: 2px;
      height: 950px;

      background: url("../../../../Resources/Images/dash_line.png") repeat-y;
      transition: 0.3s;
    }

    &.visible0:after {
      height: 0;
    }

    &.visible1:after {
      height: 348px;
    }

    &.visible2:after {
      height: 446px;
    }

    &.visible3:after {
      height: 580px;
    }

    &.visible4:after {
      height: 710px;
    }

    &.visible5:after {
      height: 886px;
    }

    &.visible6:after {
      height: 950px;
    }
  }

  @media (max-width: 1900px) {
    .topText {
      width: 1050px;

      font-weight: 300;
    }

    ul {
      li {
        font-weight: 300;
      }
    }
  }
}
