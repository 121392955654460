.container {
  padding-top: 160px;

  .title {
    width: 100%;
    text-align: center;

    font-family: 'Comfortaa', cursive;
    font-size: 50px;
    font-weight: bold;
    color: rgb(221, 46, 46);
    line-height: 0.48;

    position: relative;
    z-index: 2;
  }

  .content {
    padding-top: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1900px) {
    padding-top: 98px;

    .title {
      font-size: 36px;
      font-weight: normal;
      line-height: 0.67;
    }

    .content {
      padding-top: 60px;
    }
  }
  
  @media (max-width: 1150px) {
    padding-top: 50px;

    .title {
      font-size: 20px;
      line-height: 1.2;
    }

    .content {
      padding-top: 20px;
    }
  }
}