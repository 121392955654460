.container {
  .success {
    font-size: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
      display: flex;
      width: 100%;
      margin-bottom: 100px;
    }

    input {
      &:first-of-type {
        width: 60%;
        margin-right: 20px;
      }
      &:last-of-type {
        width: 40%;
      }
    }

    textarea {
      width: 1070px;
      resize: none;
    }

    input, textarea {
      padding-bottom: 18px;
      box-shadow:none;
      outline: none;
      background: transparent;

      border: none;
      border-bottom: 1px solid #DD2E2E;

      font-size: 30px;
      color: rgb(50, 56, 56);
    }

    .error {
      font-size: 18px;
      margin: -50px 0 30px;
      height: 20px;
    }

    button {
      cursor: pointer;
      outline: none;
      border: none;

      background-color: rgb(221, 46, 46);
      width: 150px;
      height: 40px;

      font-size: 18px;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
    }
  }
  
  @media (max-width: 1900px) {
    .success {
      font-size: 24px;
    }

    form {
      .row {
        margin-bottom: 50px;
      }

      input, textarea {
        font-size: 24px;
        font-weight: 300;
        padding-bottom: 12px;
      }

      textarea {
        width: 850px;
      }

      .error {
        margin: -35px 0 15px;
      }
    }
  }

  @media (max-width: 1150px) {
    .success {
      text-align: center;
      font-size: 18px;
    }

    form {
      width: 350px;

      input, textarea {
        border-width: 2px;

        padding-bottom: 8px;

        font-size: 16px;
        font-weight: 300;
      }

      textarea {
        width: 350px;
      }

      .error {
        margin: -40px 0 20px;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}