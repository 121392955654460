.container {
  margin-top: 160px;

  background: url("./footer.png") center no-repeat;
  background-size: cover;

  height: 320px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 1070px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    >div {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      margin-bottom: 30px;

      .text {
        padding-left: 25px;

        font-size: 30px;
        color: rgb(50, 55, 56);
        line-height: 1.3;
      }
    }

    a.phone_number {
      text-decoration: none;
      color: inherit;
    }
    
    a.link {
      text-decoration: none;
      color: indianred;
    }

    .address {
      width: 530px;
    }
  }
  
  @media (max-width: 1900px) {
    margin-top: 100px;
    height: 400px;

    .content {
      width: 900px;

      >div {
        img {
          height: 42px;
        }

        .text {
          font-size: 24px;
          font-weight: 300;
          line-height: 1.42;
        }
      }
    }
  }

  @media (max-width: 1150px) {
    margin-top: 50px;

    .content {
      width: 310px;
      padding: 25px;

      flex-direction: column;
      justify-content: center;

      >div {
        .text {
          padding-top: 5px;
          padding-left: 15px;

          font-size: 18px;
          font-weight: 300;
          line-height: 1.33;
        }


      }

      .address, .phone {
        width: 100%;
        margin-bottom: 15px;
        margin-top: 5px;
      }
      .address {
        margin-top: 25px;
      }
    }
  }
}