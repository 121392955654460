body {
  margin: 0;
  padding: 0;
}

#root {
  overflow-x: hidden;
}

body * {
  font-family: 'Roboto', sans-serif;
}
