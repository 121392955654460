.container {
  display: flex;
  z-index: 5;

  margin-top: -40px;

  .pointWrapper {
    padding: 10px 5px;

    .point {
      cursor: pointer;

      background-color: rgb(218, 220, 222);
      width: 50px;
      height: 4px;

      transition: 0.3s ease-out;

      &.selected {
        background-color: rgb(221, 46, 46);
        transition-timing-function: ease-in;
      }
    }
  }
}