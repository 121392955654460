.items {
  display: flex;
  justify-content: center;

  .item {
    background-color: rgb(233, 236, 239);
    width: 336px;
    height: 447px;

    margin-right: 20px;

    padding: 40px;


    .title {
      font-size: 40px;
      color: rgb(50, 56, 56);
      line-height: 1.1;

      margin-bottom: 25px;
    }

    font-size: 30px;
    color: rgb(50, 56, 56);
    line-height: 1.2;

    ul {
      list-style-image: url("./check.svg");
      padding-left: 26px;
      li {
        padding-left: 10px;
        margin-bottom: 10px;
      }
    }

    button {
      cursor: pointer;
      outline: none;
      border: 1px solid rgb(255, 255, 255);
      width: 239px;
      height: 40px;
      background: transparent;

      font-size: 18px;
      color: rgb(255, 255, 255);
      text-transform: uppercase;

      margin: auto auto 0;
    }

    transition: 0.4s linear;
    .description {
      opacity: 1;
      transition: 0.2s linear 0.2s;
    }

    position: relative;
    .features {
      position: absolute;
      left: 40px;
      top: 40px;
      width: 336px;
      opacity: 0;
      display: flex;
      flex-direction: column;
      visibility: hidden;
      transition: 0.2s linear;
      color: white;
    }

    &:hover {
      background-color: rgb(160, 168, 176);

      .description {
        opacity: 0;
        transition: 0.2s linear;
      }

      .features {
        opacity: 1;
        visibility: visible;
        transition: 0.2s linear 0.2s;
      }

      ul, button {
        opacity: 1;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 1900px) {
      padding: 40px 25px;

      width: 310px;
      height: 428px;

      font-size: 24px;
      font-weight: 300;
      line-height: 1.42;

      .title {
        font-size: 30px;
        line-height: 1.42;
      }

      &:not(:last-of-type) {
        margin-right: 2px;
      }

      .features {
        left: 25px;
        top: 16px;

        width: 310px;

        ul {
          li {
            font-size: 24px;
            font-weight: 300;
            line-height: 1.5;
          }
        }
      }
    }
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    align-self: center;

    .item {
      width: 310px;
      height: 110px;
      padding: 20px;
      cursor: pointer;

      &:first-of-type {
        height: 78px;
      }

      font-size: 16px;
      font-weight: 300;
      line-height: 1;

      .title {
        font-size: 18px;
        font-weight: normal;
        line-height: 1;

        margin-bottom: 10px;
      }

      &:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 2px;
      }

      &:hover {
        height: 175px;
      }

      .features {
        left: 20px;
        top: 10px;
        ul {
          li {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.13;
          }
        }
      }
    }
  }
}