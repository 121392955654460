.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 202px;
  height: 202px;

  svg {
    position: absolute;
    left: 0;
  }

  &.three {
    height: 245px;
    svg {
      left: unset;
      right: 0;
    }
  }

  tspan {
    white-space: pre
  }
  .shp0 {
    fill: #dadcde
  }
  .shp1 {
    fill: none;
    stroke: #9a9da0;
    stroke-width: 6px;
  }
  .text {
    font-size: 72px;
    line-height: 26px;
    color: #f7f6f6;
    font-family: "TT Norms", sans-serif;
    font-weight: bold;
    position: relative;
  }
  .shp3 {
    fill: #9a9da0;
  }
  .shp4 {
    fill: none;
    stroke: #9a9da0;
    stroke-width: 2px
  }

  &.hover {
    .shp1, .shp4 {
      transition: 0.3s;
      stroke: #dd2e2e;
    }

    .shp3 {
      transition: 0.3s;
      fill: #dd2e2e;
    }
  }

  &.reverse {
    svg {
      transform: scaleX(-1) translateX(100px);
    }
  }
  
  @media (max-width: 1900px) {
    svg {
      height: 153px;
      width: auto;
    }

    height: 153px;
    width: 153px;

    .text {
      font-size: 60px;
      font-weight: bold;
      line-height: 0.43;
    }

    &.three {
      svg {
        height: 180px;
      }
    }

    &.reverse {
      svg {
        transform: scaleX(-1) translateX(75px);
      }
    }
  }
}