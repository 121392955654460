.container {
  position: relative;
  z-index: 2;

  max-width: 1200px;
  margin: 0 120px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    margin-top: 80px;

    font-size: 40px;
    color: rgb(50, 56, 56);
    line-height: 1.5;

    position: relative;

    .point1 {
      position: absolute;
      left: -75px;
      bottom: -121px;
    }

    &:last-of-type {
      text-align: right;
      padding-right: 20px;
    }

    .point2 {
      position: absolute;
      right: -64px;
      top: -257px;
    }
  }

  .screen {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 10px;
  }

  @media (max-width: 1900px) {
    .text {
      margin-top: 50px;

      width: 100%;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.33;

      .point1 {
        bottom: -113px;
        left: -28px;
      }

      &:last-of-type {
        margin-top: 20px;
        padding-right: 100px;
      }
      .point2 {
        top: -184px;
        right: 30px;
      }
    }

    .screen {
      margin-top: 80px;
      z-index: 2;
    }
  }

  @media (max-width: 1150px) {
    margin: 0;
    width: 360px;

    .text {
      margin-top: 30px;

      font-size: 24px;
      font-weight: 300;
      line-height: 1.25;

      .point1 {
        left: 0;
        bottom: -77px;
      }

      &:first-of-type {
        text-align: center;
      }

      &:last-of-type {
        margin-top: 47px;
        padding: 0;
        width: 278px;
        text-align: left;

        font-size: 18px;
        font-weight: 300;
        line-height: 1.11;
      }
    }
  }
}